import type { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { HeroImage } from '@dx-ui/osc-hero-image';
import { DynamicGridWOM } from '@dx-ui/osc-dynamic-grids';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { getVisitorId } from '@dx-ui/framework-react-query';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import {
  BrandComponentThemeInline,
  BrandDataContext,
  getBrandRouteParams,
} from '@dx-ui/cpm-mapping-shared';
import type { CpmMappedPage } from '@dx-ui/cpm-sdk';
import { OneLinkWrapper } from '@dx-ui/cpm-sdk';
import type { SearchUrlParameters, BrandsQuery } from '@dx-ui/cpm-mapping';
import {
  getAppVersion,
  getAssetPath,
  getBrandSvg,
  isBrandHomePageUrlFormat,
  generateUrl,
  getBaseUrl,
  useMetrics,
  useSingleInstanceQueryStrings,
  mapDataToSpecialRates,
} from '@dx-ui/cpm-mapping';
import { BrandsSearch, type FormData } from '@dx-ui/osc-brands-search';
import { Header } from '@dx-ui/osc-header';
import { Footer } from '@dx-ui/osc-footer';
import { TextBlock } from '@dx-ui/osc-text-block';
import { type LoginResponse, useAuth, getGuestFirstName } from '@dx-ui/framework-auth-provider';
import {
  BrandShowcase,
  BrandThemeWrapper,
  ImageHeadliner,
  ImageHeadlinerItem,
} from '@dx-ui/osc-marketing';

import { GatedModal } from '../../components/GatedModal';
import { serverSideGetCompanyForPreferredRatesByAccountIdQuery } from '../../generated/react-query';

import {
  getCommonServerSideFetches,
  getCommonServerSidePostFetches,
} from '../../utils/common-server-side-fetches';
import { getFooterProps, getHeaderProps } from '../../utils/static-pages-data';
import { createAccountIdForQuery, searchComponentParams } from '../../utils/static-pages';
import { Layout } from '@dx-ui/osc-marketing-layout';
import { goUserTypes } from '@dx-ui/osc-brands-header';

function handleSearchRedirect(searchUrlParameters: SearchUrlParameters) {
  const deepLinkUrl = generateUrl(searchUrlParameters);

  if (isBrowser && deepLinkUrl) {
    window.location.assign(deepLinkUrl);
  }
}

function removeUndefinedes<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Impossible<K extends keyof any> = { [P in K]: never };
type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  try {
    const commonFetches = await getCommonServerSideFetches(ctx);
    if ('notFound' in commonFetches) return commonFetches;
    const { env, queryClient, localeCode, translations, oneLinkProps } = commonFetches;

    const accountId = createAccountIdForQuery(ctx.resolvedUrl, ctx.query);

    const companyData = await serverSideGetCompanyForPreferredRatesByAccountIdQuery(queryClient, {
      accountid: accountId,
      language: localeCode,
    });

    const partner = companyData?.altCorporateAccount?.partner;

    const partnerBrandShowcaseItems =
      partner?.brandShowcase?.items?.map(({ brandCode, label, shortDescription }) => ({
        code: brandCode ?? '',
        name: label ?? '',
        shortDescription: shortDescription ?? '',
      })) ?? [];

    const textHeadliner = partner?.textHeadliner;
    const imageHeadlinerItems: Array<
      NoExtraProperties<React.ComponentProps<typeof ImageHeadlinerItem>>
    > = textHeadliner?.headline
      ? [
          {
            id: 'prefererred-rates-image-headliner-item',
            headline: textHeadliner?.headline ?? '',
            imageAltText: undefined,
            imageUrl: undefined,
            cmsTranslationClasses: undefined,
            link: textHeadliner?.links?.[0]?.linkUrl
              ? {
                  adaDescription: textHeadliner?.links?.[0]?.adaDescription ?? undefined,
                  isNewWindow: textHeadliner?.links?.[0]?.isNewWindow ?? undefined,
                  label: textHeadliner?.links?.[0]?.linkLabel ?? undefined,
                  url: textHeadliner?.links?.[0]?.linkUrl,
                }
              : undefined,
          },
        ]
      : [];

    const masthead = partner?.masthead;
    const link = masthead?.links?.[0];

    let heroImage: NoExtraProperties<React.ComponentProps<typeof HeroImage>> | null = {
      headline: masthead?.headline ?? '',
      longDescription: masthead?.description,
      shortDescription: masthead?.description ?? '',
      imageAltText: masthead?.imageCompound?.[0]?.altText ?? '',
      aspectRatios: { desktop: '18:5' as const, mobile: '21:9' as const },
      captionData: {},
      badgeImageUrl: null,
      badgeImageAltText: '',
      alignContent: 'left' as const,
      brandComponentTheme: undefined,
      isAnimated: false,
      hasHorizontalLine: false,
      link: link
        ? {
            adaDescription: link.adaDescription ?? '',
            label: link.linkLabel ?? '',
            url: link.linkUrl ?? '',
            isNewWindow: link.isNewWindow ?? false,
          }
        : null,
      image: {
        desktopUrl:
          masthead?.imageCompound[0]?.image?.variants?.fullWidth?.find?.(
            ({ size }) => size === 'md'
          )?.cdnLink ?? '',
        mobileUrl:
          masthead?.imageCompound[0]?.image?.variants?.fullWidth?.find?.(
            ({ size }) => size === 'sm'
          )?.cdnLink ?? '',
      },
    };
    heroImage = heroImage.image.desktopUrl ? heroImage : null;

    const { logoUrl } = partner!;
    const logoAltText = partner?.logo?.[0]?.altText ?? '';

    const { headline, longDescription } = partner!;
    let textBlock: NoExtraProperties<React.ComponentProps<typeof TextBlock>> | null = {
      isAnimated: false,
      textAlign: 'center' as const,
      data: [
        ...(headline
          ? [{ kind: 'heading' as const, content: '## ' + headline.replace(/^#*/g, '') }]
          : []),
        ...(longDescription ? [{ kind: 'description' as const, content: longDescription }] : []),
      ],
    };
    textBlock = textBlock?.data?.length ? textBlock : null;

    const threeSixNineGrid = partner?.threeSixNineGrid;
    let grid: NoExtraProperties<React.ComponentProps<typeof DynamicGridWOM>> | null = {
      id: 'threeSixNineWOMGrid-0',
      imageAspectRatio: '3:2' as const,
      isAnimated: false,
      listSubheading: undefined,
      brandComponentTheme: undefined,
      listHeadline: threeSixNineGrid?.headline ?? '',
      link: {
        adaDescription: threeSixNineGrid?.links?.[0]?.adaDescription ?? '',
        isNewWindow: threeSixNineGrid?.links?.[0]?.isNewWindow ?? false,
        label: threeSixNineGrid?.links?.[0]?.linkLabel ?? '',
        url: threeSixNineGrid?.links?.[0]?.linkUrl ?? '',
      },

      items:
        threeSixNineGrid?.items?.map((item) => {
          const image = item.imageCompound?.[0]?.image?.variants?.threeSixNineGrid.find(
            ({ size }) => size === 'md'
          );

          const expansionImage =
            item.imageCompound?.[0]?.image?.variants?.threeSixNineExpansionPanel.find(
              ({ size }) => size === 'md'
            );

          return {
            id: item._id ?? '',
            $ref: '',
            headline: item.headline ?? '',
            content: item.shortDescription ?? '',
            segmentIds: item.segmentIds,
            caption: '',
            labelTxt: '',
            cmsTranslationClasses: undefined,

            imageUrl: image?.cdnLink ?? '',
            imageAltTxt: item.imageCompound?.[0]?.altText ?? '',
            modalImageUrl: expansionImage?.cdnLink ?? '',

            link: {
              adaDescription: item?.link?.adaDescription ?? '',
              isNewWindow: item?.link?.isNewWindow ?? false,
              label: item?.link?.linkLabel ?? '',
              url: item?.link?.linkUrl ?? '',
            },
          };
        }) ?? [],
    };
    grid = grid.items.length ? grid : null;

    const mappedPage: CpmMappedPage = {
      brandCode: 'WW' as const,
      pathname: ctx.resolvedUrl,
      seoImage: heroImage?.image?.desktopUrl ?? '',
      articleTitle: '',
      hidePageFooter: false,
      hidePageHeader: false,
      isHeaderH1: false,
      languageCode: 'en',
      localeCode: 'en',
      pageType: 'brandHomepage',
      seo: {
        hideSearchEngine: true,
        metaKeywords:
          'hotels,hotel reservations,hotel discount,leisure hotels,business hotels,www.hilton.com,Hilton Hotels & Resorts',
        pageHeading: '',
        pageTitle: `Hilton | ${partner?.corporateId ?? ''} Preferred Rates`,
        metaDescription: partner?.longDescription ?? '',
      },
      analyticsTagging: {
        pageDetailOne: partner?.category ?? '',
        pageDetailTwo: accountId,
        pageDetailThree: 'Terms',
        primaryCategory: 'Brand',
        adobePageType: 'Corporate Advantage',
        trackEventType: 'brandPageView',
        brandCode: 'HI',
      },
    };

    const { brandData, brandShowcaseData } = await getCommonServerSidePostFetches(
      queryClient,
      localeCode,
      'HH',
      partnerBrandShowcaseItems.length > 0
    );

    const brandShowcaseMap: Record<string, BrandsQuery['brands'][number]> =
      brandShowcaseData?.reduce((acc, item) => {
        return {
          ...acc,
          [item.code]: item,
        };
      }, {}) ?? {};

    const brandShowcaseItems = partnerBrandShowcaseItems.length
      ? partnerBrandShowcaseItems.map((item) => {
          const brand = brandShowcaseMap[item.code];

          return {
            ...item,
            code: item.code,
            name: brand?.name ?? '',
            url: brand?.url ?? '',
            label: brand?.name ? `Visit ${brand.name}` : undefined,
          };
        })
      : undefined;

    const props = removeUndefinedes({
      ...translations,
      ...oneLinkProps,
      env,
      localeCode,
      brandCode: 'WW',
      brandData,
      companyData,
      brandShowcaseItems,
      isPortfolioHeaderTestActive: false,
      featureToggles: [],
      imageHeadlinerItems,
      heroImage,
      textBlock,
      grid,
      mappedPage,
      logoUrl,
      logoAltText,
      searchComponentParams,
    });

    return { props };
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error(`Something went wrong when retrieving data for ${ctx.req.url}`);
    //eslint-disable-next-line no-console
    console.error(error);
    return { notFound: true };
  }
}

type Props = Exclude<Awaited<ReturnType<typeof getServerSideProps>>['props'], undefined>;

export default function PreferredRatesPage({
  brandCode,
  brandData,
  brandShowcaseItems,
  localeCode,
  env,
  imageHeadlinerItems,
  heroImage,
  textBlock,
  grid,
  mappedPage,
  logoUrl,
  logoAltText,
  companyData,
  searchComponentParams,
}: Props) {
  const [trackSignIn, setTrackSignIn] = useState(false);
  const router = useRouter();
  const { authClient, login, logout, guestInfo, isLoading, isAuthenticated } = useAuth();

  if (!authClient) {
    throw new Error('Unable to load authClient');
  }
  const sessionId = getVisitorId();

  const { language: languageCode } = new Intl.Locale(localeCode);
  const baseAppUrl = getBaseUrl(env, `${languageCode}${router.asPath}`);
  const {
    brandPath: fullBrandPath,
    auxPageSlug,
    brandSlug,
  } = getBrandRouteParams(router.asPath, languageCode);

  const metrics = useMetrics(localeCode, fullBrandPath, mappedPage, brandData, false, env);

  const {
    cid = '',
    awc = '',
    dclid = '',
    gclid = '',
    wtmcid = '',
  } = useSingleInstanceQueryStrings();

  const onSearchSubmit = (formData: FormData, url: string) => {
    setTimeout(
      () =>
        handleSearchRedirect({
          formData,
          awc,
          cid,
          dclid,
          gclid,
          isGroupSearch: false,
          brandCode,
          languageCode,
          url,
          wtmcid,
          baseAppUrl,
          env,
        }),
      0
    );
  };

  const { additionalQS, specialRates } = mapDataToSpecialRates(searchComponentParams, companyData);
  const { pathname } = new URL(baseAppUrl);

  const onSignInAttempt = useCallback(
    (data: LoginResponse): Promise<void> => {
      if (data) {
        setTrackSignIn(true);
        return login(data);
      }
      return Promise.reject();
    },
    [login, setTrackSignIn]
  );

  const onSignOut = useCallback((): Promise<void> => logout(), [logout]);

  useEffect(() => {
    if (trackSignIn && !isLoading) {
      // We only want this to trigger once when a user has successfully signed in
      setTrackSignIn(false);
      const availableGoUserTypes: string[] | null = guestInfo?.hhonors?.packages?.filter
        ? guestInfo?.hhonors?.packages
            ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
            ?.map((pack) => pack && pack.packageName)
        : null;

      metrics.trackUserLoggedIn?.([
        {
          hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber ?? '',

          tierName: guestInfo?.hhonors?.summary?.tierName ?? '',
          points: guestInfo?.hhonors?.summary?.totalPointsFmt ?? '',
          goUserTypes: availableGoUserTypes,
        },
      ]);
    }
  }, [guestInfo, isLoading, trackSignIn, metrics]);

  return (
    <>
      <BrandDataContext.Provider value={brandData ?? {}}>
        <BrandThemeWrapper brandCodeForTheme={brandCode === 'WW' ? 'HH' : brandCode}>
          <Layout
            assetPath={getAssetPath(env)}
            isBrandHomePage={isBrandHomePageUrlFormat(pathname, brandCode)}
            brandSvgPath={getBrandSvg(env, brandCode)}
            ohwBaseUrl={env.OHW_BASE_URL ?? ''}
            appVersion={getAppVersion(env)}
            brandData={brandData ?? undefined}
            top={
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline
                    componentParams={{}}
                    brandCode={brandCode}
                    componentClassName="header"
                  >
                    <Header
                      {...getHeaderProps(env)}
                      onSignInAttempt={onSignInAttempt}
                      onSignOut={onSignOut}
                      user={
                        isAuthenticated && guestInfo
                          ? {
                              name: getGuestFirstName({ guestInfo }),
                              honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
                              honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
                              hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
                              honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
                            }
                          : undefined
                      }
                    />
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            }
            bottom={
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline componentParams={{}} brandCode={brandCode}>
                    <Footer {...getFooterProps(env)} />
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            }
            brandCode={brandCode}
            localeCode={localeCode}
            baseAppUrl={baseAppUrl}
            fullBrandPath={fullBrandPath}
            seo={mappedPage.seo}
            articleTitle={mappedPage.articleTitle}
            seoImage={mappedPage.seoImage}
            includeBackToTopButton={true}
          >
            <div>
              <OneLinkWrapper>
                <LocationProvider api={env?.LOCATION_PROVIDER_API ?? ''}>
                  <BrandComponentThemeInline
                    componentParams={searchComponentParams}
                    brandCode={brandCode}
                  >
                    {/*
                     * Add a key to the search component in Editor mode to force a rerender
                     * when special rates have changed. This is to fix a memorisation bug in the
                     * Search component in dx-ui.
                     */}
                    <BrandsSearch
                      brandCode={brandCode}
                      brandSlug={brandSlug}
                      baseAppUrl={baseAppUrl}
                      auxPageSlug={auxPageSlug}
                      isBrandRoute={true}
                      fullBrandPath={fullBrandPath}
                      isGroupSearch={false}
                      isDayUse={false}
                      defaultLanguage="en"
                      languageCode={localeCode}
                      autocompleteUri={env.DX_AUTOCOMPLETE_URI ?? ''}
                      additionalQSParameters={additionalQS}
                      specialRates={specialRates}
                      sessionId={sessionId ?? ''}
                      onSearchSubmit={onSearchSubmit}
                      isPartnerBrand={false}
                    />
                  </BrandComponentThemeInline>
                </LocationProvider>
              </OneLinkWrapper>
            </div>

            {imageHeadlinerItems.length ? (
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline componentParams={{}} brandCode={brandCode}>
                    <ImageHeadliner label="imageHeadliner">
                      {imageHeadlinerItems.map((props, index) => (
                        <ImageHeadlinerItem {...props} key={props.id} index={index} />
                      ))}
                    </ImageHeadliner>
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            ) : null}

            {heroImage ? (
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline componentParams={{}} brandCode={brandCode}>
                    <HeroImage {...heroImage} />
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            ) : null}

            {textBlock ? (
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline componentParams={{}} brandCode={brandCode}>
                    <div className="container">
                      <div
                        className="flex flex-col items-center justify-center pt-5 md:flex-row md:text-left"
                        data-testid="logoAndTextBlockContainer"
                      >
                        {logoUrl ? (
                          <>
                            {/*We lack any of the metadata next/image requires to render this image, just use an img tag */}
                            <div className="flex w-[30%] items-center justify-center">
                              <img className="" src={logoUrl} alt={logoAltText} />
                            </div>
                            <TextBlock {...textBlock} textAlign="left" />
                          </>
                        ) : (
                          <TextBlock {...textBlock} />
                        )}
                      </div>
                    </div>
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            ) : null}

            {grid ? (
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline
                    componentClassName="threeSixNineWOMGrid"
                    componentParams={{ display: 'wom' }}
                    brandCode={brandCode}
                  >
                    <div className="pt-6">
                      <DynamicGridWOM {...grid} />
                    </div>
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            ) : null}

            {brandShowcaseItems ? (
              <div>
                <OneLinkWrapper>
                  <BrandComponentThemeInline componentParams={{}} brandCode={brandCode}>
                    <BrandShowcase
                      id="brand-showcase"
                      onItemClicked={undefined}
                      items={brandShowcaseItems ?? []}
                      /*these should be hard coded (according to dx-hotels-ui/src/components/brand-showcase/index.tsx)*/
                      logoUrl="/modules/assets/svgs/logos/brand"
                      baseUrl="/en"
                    />
                  </BrandComponentThemeInline>
                </OneLinkWrapper>
              </div>
            ) : null}
          </Layout>
        </BrandThemeWrapper>
      </BrandDataContext.Provider>
      <GatedModal domains={companyData?.altCorporateAccount?.domains} brandCode={brandCode} />
    </>
  );
}
